<template>
  <div class="icon-mark">
    <v-icon
      v-on="$listeners"
      :ref="refIcon"
      id="icon1"
      class="icon"
      :class="classIcon"
      :color="color"
      :x-small="xSmall"
      :small="small"
      :normal="normal"
      :large="large"
      :x-large="xlarge"
      :dense="dense"
    >{{$icon(computedIconName)}}</v-icon>
    <v-icon
      v-if="computedMarkIconName"
      id="icon2"
      class="icon"
      :class="classIcon"
      :color="computedMarkColor"
      :size="computedMarkSize"
      :dense="dense"
    >{{$icon(computedMarkIconName)}}</v-icon>
    <div
      v-if="computedIsStrike"
      class="icon-mark-inner"
      :class="computedStrikeStyle"
    >
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    computedIconName () {
      return this.iconName
    },
    computedMarkIconName () {
      return this.computedIconMarkProps.markIconName
    },
    computedMarkColor () {
      return this.computedIconMarkProps.markColor
    },
    computedMarkSize () {
      return this.computedIconMarkProps.markSize
    },
    computedIsStrike () {
      return this.computedIconMarkProps.isStrike
    },
    computedStrikeStyle () {
      if (this.color) {
        return `${this.color}`
      } else {
        return `${this.$vuetify.theme.isDark ? 'white' : 'grey darken-1'}`
      }
    },
    computedIconMarkProps () {
      const _ = Object.assign({
        markIconName: null, // markIconName: xyz ? 'i.AlertCircleOutline' : null,
        markSize: 24,
        markColor: this.$vuetify.theme.isDark ? 'white' : '',
        isStrike: false
      }, this.iconMarkProps)

      return _
    }
  },
  props: {
    classIcon: [Object, String],
    dense: Boolean,
    refIcon: String,
    color: String,
    iconName: String,
    disabled: Boolean,
    xSmall: Boolean,
    small: Boolean,
    normal: Boolean,
    large: Boolean,
    xlarge: Boolean,
    iconMarkProps: Object
  }
}
</script>

<style lang="stylus" scoped>
.icon-mark
  display flex
  position relative

.icon-mark .icon#icon1
  top 0
  left 0

.icon-mark .icon#icon2
  z-index 20 !important
  position absolute !important
  right 0
  bottom 0

.icon-mark .icon-mark-inner
  z-index 0 !important
  position absolute
  width 100%
  height 100%
  // clip-path polygon(90% 0, 100% 0%, 10% 100%, 0% 100%)
  clip-path polygon(0 0, 15% 0, 100% 100%, 85% 100%)
  top 0
  bottom 0
  // opacity 0.5
</style>
